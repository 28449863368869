.full-width {
	width: 100% !important;
}

.full-height {
	height: 100vh;
}

.hide {
	display: none !important;
}

.btn-default {
	&:focus {
		background-color: transparent;
		border: 1px solid #e7eaec;
	}
}

.bg-transparent {
	background-color: transparent !important;
}

.bg-success {
	background-color: #1ab394 !important;
}

.bg-danger {
	background-color: #ed5565 !important;
}

// css to show/hide content
.panel-wrapper {
	position: relative;
	padding: 0 !important;
	.see-less, .see-more {
		position: absolute;
		bottom: -25px;
		z-index: 100;
		text-align: center;
		width: 100%;
		font-size: 16px;
		font-weight: 400;
		color: #1ab394;
		text-transform: capitalize;
		margin: 10px;
		i {
			cursor: pointer;
			font-size: 35px;
		}
	}
	.see-less ~ .panel {
			max-height: none;
	}
	.see-less + .panel ~ .fade {
		background: 0;
	}
	.panel {
		position: relative;
		margin: 2em auto;
		max-height: 130px;
		overflow: hidden;
		-webkit-transition: max-height .5s ease;
		transition: max-height .5s ease;
	}
	.fade {
		background: -webkit-gradient(linear, left top, left bottom, from(rgba(100, 100, 100, 0)), to(#ffffff));
		background: linear-gradient(to bottom, rgba(100, 100, 100, 0) 0%, #ffffff 100%);
		height: 100px;
		margin-top: -100px;
		position: relative;
		opacity: 1;
	}
}

.chart-container {
	margin: 0 !important;
	padding: 0 10px;
	min-height: 500px;
	.card-heading {
		h4 {
			width: 50%;
			line-height: 34px;
		}
	}
	.card-description-wrapper {
		min-height: 420px;
	}
	.type {
		width: 100%;
		float: right;
		.type-option {
			float: right;
			input {
				width: 280px;
			}
		}
	}
}

.date-selection-wrapper {
	position: relative;
	.date-label {
		font-size: 13px;
		line-height: 34px;
		margin-right: 5px;
	}
	.date-range-label {
		line-height: 34px;
		font-size: 14px;
		border: 1px solid rgba(0, 0, 0, 0.2);
		padding: 0 25px 0 15px;
		cursor: pointer;
		margin-bottom: 0;
		-webkit-user-select: none;
		   -moz-user-select: none;
			-ms-user-select: none;
				user-select: none;
		i {
			position: absolute;
			right: 10px;
			line-height: 34px;
		}
	}
	.date-preview-wrapper {
		z-index: 999;
		.static-ranges {
			position: absolute;
			right: 0;
			top: 40px;
			background-color: #f7f7f7;
			height: 315px;
			z-index: 999;
			ul {
				display: -webkit-box;
				display: flex;
				-webkit-box-orient: vertical;
				-webkit-box-direction: normal;
						flex-direction: column;
				list-style-type: none;
				padding: 0;
				-webkit-box-align: center;
						align-items: center;
				height: 100%;
				justify-content: space-around;
				li {
					width: 150px;
					padding: 0px 10px;
					height: 100%;
					text-align: center;
					cursor: pointer;
					border-bottom: 1px solid #dddddd;
					display: -webkit-box;
					display: flex;
					-webkit-box-pack: center;
							justify-content: center;
					-webkit-box-align: center;
							align-items: center;
					&:hover, &.active {
						background-color: #1ab394;
						color: #ffffff;
					}
					&:nth-last-child(1) {
						line-height: 45px;
						height: 100%;
						border-bottom: none;
					}
				}
			}
		}
		.rdr-Calendar {
			position: absolute;
			right: 150px;
			top: 40px;
			background-color: #f2f2f2 !important;
		}
		.date-range-footer {
			position: absolute;
			width: 280px;
			right: 150px;
			top: 300px;
			background-color: #f2f2f2;
			z-index: 999;
			.date-range-actions {
				width: 100%;
				height: 35px;
			}
		}
	}
}

.ui-chart-tooltip {
	pointer-events: none;
	opacity: 0;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 10;
	.ui-chart-tooltip-content {
		width: auto;
		height: 72px;
		box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.5);
		background-color: #ffffff;
		padding: 5px 8px;
		.tooltip-heading {
			font-size: 12px;
			font-weight: bold;
			color: #ffffff;
		}
		.qty,
		.date {
			display: -webkit-box;
			display: flex;
			padding: 8px 0 0;
			color: #000000;
			font-size: 12px;
		}
	}
}

td {
	.form-control {
		line-height: 34px !important;
	}
	::-webkit-input-placeholder {
		font-size: 11px;
		// line-height: 34px !important;
	}
	:-ms-input-placeholder {
		font-size: 11px;
		// line-height: 34px !important;
	}
	::-ms-input-placeholder {
		font-size: 11px;
		// line-height: 34px !important;
	}
	::placeholder {
		font-size: 11px;
		// line-height: 34px !important;
	}
	input,
	select {
		background: rgba(58, 179, 148, 0.05) !important;
		border: 1px solid rgba(58, 179, 148, 1) !important;
		color: rgba(0, 0, 0, 0.8) !important;

		&::-webkit-input-placeholder {
			color: rgba(0, 0, 0, 0.8) !important;
			opacity: 1;
		}

		&:-ms-input-placeholder {
			color: rgba(0, 0, 0, 0.8) !important;
			opacity: 1;
		}

		&::-ms-input-placeholder {
			color: rgba(0, 0, 0, 0.8) !important;
			opacity: 1;
		}

		&::placeholder {
			color: rgba(0, 0, 0, 0.5) !important;
			opacity: 1;
		}

		&:focus {
			background: white !important;
		}
	}
}

#page-wrapper {
	background-color: #f3f3f3;
}

th {
	background-color: rgba(47, 64, 80, 0.7) !important;
	color: #ffffff;
	border-right: 1px solid rgba(255, 255, 255, 0.3) !important;
	border-bottom: 0 !important;
}

.sorting_asc,
.sorting,
.sorting_desc {
	// color: white !important;

	&::after {
		opacity: 1 !important;
	}
}

.inactive-sort {
	color: #cccccc;
}

.search-org {
	width: 300px;
}

.filter-org {
	input {
		margin: 0 auto;
	}
}

.search-id {
	width: 10%;
}

.search-name1 {
	width: 10%;
}

.search-hw_id,
.search-auto_license,
.search-windows_version,
.search-pb_p_version,
.search-version {
	width: 10%;
}

.search-customer_id {
	width: 10%;
}

.search-name {
	width: 10%;
}

.search-label {
	width: 10%;
}

.search-labelName {
	width: 10%;
}

.search-email {
	width: 10%;
}

.search-address {
	width: 10%;
}

.search-owner {
	width: 10%;
}

.search-last_sync_at {
	width: 10%;
}

.search-last_update {
	width: 10%;
}

.search-actions {
	width: 10%;
}

.pagination-btn {
	&:hover {
		color: black;
	}
}

.pagination-active {
	background-color: #3ab394;
	color: white;

	&:hover,
	&:focus {
		color: white !important;
	}
}

.form-overlay {
	opacity: 0.5;
	pointer-events: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	position: relative;

	.overlay-spinner {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		text-align: center;
		z-index: 9;
	}
}

.edit-role-form-wrapper {
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.checkbox-wrapper {

	input[type=checkbox],
	label {
		cursor: pointer;
	}
}

.text-green {
	color: #1ab394;
	a {
		color: #1ab394;
	}
}

.text-red {
	color: #ed5565;
	a {
		color: #ed5565;
	}
}

.cursor-not-allowed {
	cursor: not-allowed !important;
}

.mb-0 {
	margin-bottom: 0 !important;
}

.custom-tabs {
	background: white;

	.btn-active {
		border-bottom: 2px solid #1ab394 !important;
	}

	button {
		text-transform: uppercase;
		margin-bottom: 0 !important;
		cursor: pointer !important;
		border-radius: 0;
		border: 0 !important;
		border-bottom: 2px solid transparent;

		&:hover {
			background: white;
		}

		&:focus {
			background: white;
			border: 0;
			outline: 0;
		}
	}
}

.float-left {
	float: left;
}

.org-label {
	color: #ffffff;

	span {
		border-radius: 4px;
		padding: 1px 3px;
	}
}

.customer-table {
	overflow-y: auto;
	height: calc(75vh - 250px);
	&::-webkit-scrollbar {
		width: 5px;
	}
	&::-webkit-scrollbar-track {
		-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.2);
	}
	&::-webkit-scrollbar-thumb {
	  background-color: #1ab394;
	  outline: 1px solid #1ab394;
	}
}

.table-btn-wrapper {
	-webkit-box-pack: justify;
	justify-content: space-between;
}

.custom-abbr {
	cursor: auto !important;
	border: none !important;
}

body,
.modal {
	&::-webkit-scrollbar {
		width: 4px;
	}

	&::-webkit-scrollbar-track {
		-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
	}

	&::-webkit-scrollbar-thumb {
		background-color: #1ab394;
		outline: 0;
	}
}

.table-pagination-wrapper {
	position: relative;
	// right: 10px;
	text-align: right;
	width: 100%;
}

.page-count {
	float: left;
	line-height: 38px;
}

.page-selection {
	display: inline;
	width: 80px !important;
	select {
		display: inline;
		margin-right: 10px;
		width: 80px !important;
	}
}

.edit-profile-btn {
	position: absolute;
	right: 10px;
	top: 2px;
	font-size: 23px;
	cursor: pointer;

	i {
		padding-top: 2px;
	}
}

.control-label {
	margin-bottom: 5px !important;
}

.cursor-pointer {
	cursor: pointer !important;
}

.p-5 {
	padding: 5px !important;
}

.pl-0 {
	padding-left: 0 !important;
}

.mr-0 {
	margin-right: 0 !important;
}

.mr-10 {
	margin-right: 10px !important;
}

.confirm-icon {
	color: #ed5564;
	font-size: 30px;
	text-align: center;

	i {
		border: 1px solid #ed5564;
		width: 50px;
		border-radius: 50%;
		padding: 10px;
	}
}

.page-heading {
	margin-bottom: 20px;
}

.input-label-wrapper {
	margin-bottom: 10px !important;
}

.table-wrapper {
	padding-top: 0;
}

.custom-card {
	position: relative;
	background-color: #ffffff;
	margin: 0 10px;

	&.company-detail-card {
		.card-description-wrapper {
			.desc-item {
				p {
					.fa-check-circle, .fa-times-circle {
						font-size: 16px;
					}
				}
			}
		}
	}

	&.license-org-card {
		.card-description-wrapper {
			.desc-item {
				p {
					width: calc(100% - 110px);
				}
			}
		}
	}

	&.credential-info-card {
		margin: 0;
		margin-top: -15px;
		.card-description-wrapper {
			.desc-item {
				p {
					width: calc(100% - 110px);
					word-wrap: break-word;
				}
			}
		}
	}

	.card-heading {
		padding: 10px;
		border-bottom: 1px solid #e7eaec;

		h4 {
			margin: 0;
			letter-spacing: 0.5px;
			text-transform: capitalize;
		}

	}

	.card-description-wrapper {
		padding: 10px;

		.desc-item {
			margin-bottom: 10px;

			&:last-child {
				margin-bottom: 0;
			}

			label {
				margin-right: 10px;
				margin-bottom: 0;
				letter-spacing: 0.5px;
				font-weight: 700;
			}

			p {
				margin-bottom: 0;
				letter-spacing: 0.5px;
				font-weight: 600;
			}
		}
	}

	.info-label {
		padding-left: 10px;
		text-decoration: underline;
	}

}

.step-wrapper {
	font-size: 15px;
	.step-title-wrapper {
		width: 100%;
		display: -webkit-box;
		display: flex;
		padding: 10px 0;
		border-radius: 5px;
		&.title-bg-grey {
			background-color: #dedede;
			color: #000000;
		}
		&.title-bg-green {
			background-color: #1ab394;
			color: #000000;
		}
		.step-number {
			padding-left: 10px;
		}
		.step-title {
			width: 100%;
			text-align: center;
		}
	}
	.content-wrapper {
		padding: 25px 20px;
		text-align: center;
		.action-wrapper {
			margin-top: 20px;
			.action {
				margin-top: 10px;
				.label {
					min-width: 100px;
					text-align: right;
					margin-right: 10px;
					margin-bottom: 0;
					letter-spacing: 0.5px;
					font-weight: 700;
					font-size: 15px !important;
					display: inline-block;
					max-width: 100%;
					background: transparent;
				}
				.switch-btn {
					display: -webkit-box;
					display: flex;
					height: 22px;
					margin: 0 20px;
					input {
						display: none;
						&:checked~small {
							background: #1ab394;
							-webkit-transition: .3s;
							transition: .3s;
							&::before {
								-webkit-transform: translate(25px, 0px);
								transform: translate(25px, 0px);
								-webkit-transition: .3s;
								transition: .3s;
								background: #fff;
							}
						}
					}
					small {
						display: inline-block;
						width: 50px;
						height: 100%;
						border: 2px solid #1ab394;
						border-radius: 30px;
						position: relative;
						cursor: pointer;
						&::before {
							content: "";
							position: absolute;
							width: 10px;
							height: 10px;
							background: #1ab394;
							border-radius: 50%;
							top: 4px;
							left: 4px;
							-webkit-transition: .3s;
							transition: .3s;
							box-shadow: -3px 0 3px rgba(0, 0, 0, 0.1);
						}
					}
				}
			}
		}
	}
}

.sync-btn {
	font-size: 14px;
	padding: 5px 10px;
	min-width: 130px;
}

.verify-icon, .close-icon {
	padding-left: 8px;
	font-size: 18px;
	&.medium {
		font-size: 16px !important;
	}
}

.no-background {
	background-color: unset !important;
}

.no-border-bottom {
	border-bottom: 0 !important;
}

.subscription-container {
	display: -webkit-box;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	        flex-direction: row;
	flex-wrap: wrap;
	.subscription-card {
		width: 20%;
		padding: 5px;
		.card {
			background-color: #ffffff;
			border-radius: 5px;
			padding: 10px;
			height: 250px;
			box-shadow: -2px 0px 9px -2px rgba(214,214,214,1);
			overflow-y: auto;
			&:first-child {
				margin-left: 0 !important;
			}
			&:last-child {
				margin-right: 0 !important;
			}
			&::-webkit-scrollbar {
				width: 3px;
			}
			&::-webkit-scrollbar-track {
				-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
			}
			&::-webkit-scrollbar-thumb {
			  background-color: #1ab394;
			  outline: 1px solid #1ab394;
			}
			.title-wrapper {
				display: -webkit-box;
				display: flex;
				-webkit-box-orient: horizontal;
				-webkit-box-direction: normal;
				        flex-direction: row;
				-webkit-box-align: center;
				        align-items: center;
				line-height: 18px;
				margin-top: 5px;
				margin-bottom: 15px;
				.title {
					font-size: 15px;
					margin-top: 0;
					margin-bottom: 0;
					font-weight: 700;
					text-transform: capitalize;
				}
			}
			.info {
				padding: 0;
				margin-bottom: 0;
				margin-top: 8px;
				&.bold {
					font-weight: 600;
				}
			}
			.additional-info {
				.info-label {
					font-size: 14px;
					padding: 0;
					margin: 15px 0;
					font-weight: 600 !important;
				}
				.info-item {
					display: -webkit-box;
					display: flex;
					-webkit-box-orient: horizontal;
					-webkit-box-direction: normal;
					        flex-direction: row;
					-webkit-box-pack: justify;
					        justify-content: space-between;
				}
			}
		}
	}
}

.profile-wrapper {
	background-color: #fff !important;
	border-radius: 10px;
	padding: 0 10%;
	font-weight: 400;
	color: #000 !important;

	.profile-field {
		padding: 10px;
		height: 30px;
		text-transform: capitalize;
	}

	.profile-value {
		padding: 10px;
	}
}

.float-right {
	float: right !important;
}

.checkbox-wrapper {
	padding-left: 10px;
}

.display-inline-flex {
	display: -webkit-inline-box !important;
	display: inline-flex !important;
}

.login-wrapper {
	height: 100vh;
	width: 100vw;
}

.validate-card-wrapper {
	.card {
		display: -webkit-box;
		display: flex;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		        flex-direction: column;
		-webkit-box-pack: center;
		        justify-content: center;
		-webkit-box-align: center;
		        align-items: center;
		background-color: #ffffff;
		padding: 20px 10%;
		font-weight: 400;
		color: #000 !important;
		min-height: 200px;
		margin-bottom: 10px;
		.loading-icon {
			font-size: 70px !important;
			&.success {
				color: #1ab394 !important;
			}
			&.danger {
				color: #ed5565 !important;
			}
		}
		.content-wrapper {
			margin-top: 20px;
			font-size: 20px;
			text-align: center;
			// opacity: 0.4;
			.val-text {
				text-align: center;
				margin-bottom: 20px;
			}
		}
	}
	.loginscreen.middle-box {
		width: 400px;
	}
	.logo-name {
		text-align: center;
	}
}

.permission-box-wrapper {
	margin: 0 auto;
	padding: 20px 0;
	width: 500px;
	hr {
		margin: 0;
	}
	.ibox {
		border: 1px solid #dedede;
		margin: 5vh 0 0;
		box-shadow: 0px 0px 2px 1px rgba(0,0,0,0.1);
	}
	.ibox-title {
		font-size: 25px;
		padding: 15px;
		border: 0;
	}
	.ibox-content {
		margin: 0 auto;
		border-width: 2px 0 0;
		padding: 0;
		.content {
			font-size: 16px;
			padding: 25px 20px;
			.font-bold {
				font-weight: 600;
			}
			.msg-wrapper {
				display: -webkit-box;
				display: flex;
				-webkit-box-orient: vertical;
				-webkit-box-direction: normal;
				        flex-direction: column;
				-webkit-box-pack: center;
				        justify-content: center;
				-webkit-box-align: center;
				        align-items: center;
			}
			.content-header-text {
				text-align: center;
			}
			.info-list {
				.info {
					margin: 20px 0;
					.info-label-text {
						margin: 0;
						font-weight: 600;
						font-size: 15px;
					}
					.info-short-desc {
						margin: 0;
						font-size: 14.5px;
						color: #8e8e8e;
					}
				}
			}
		}
		.ibox-action {
			padding: 20px 20px;
			.action-btn-wrapper {
				display: -webkit-box;
				display: flex;
				-webkit-box-pack: end;
				        justify-content: flex-end;
			}
		}
	}
}

.shop-form-wrapper {
	margin: 0 auto;

	.ibox-content {
		form {
			max-width: 500px;
			margin: 0 auto;
		}
	}
}

.success-elem {
	color: #1ab394 !important;
}

.danger-elem {
	color: #ed5565 !important;
}

.text-capitalize {
	text-transform: capitalize !important;
}

.table-actions {
	width: 170px;
	text-align: center;
}

.action-btn {
	min-width: 40px !important;
	width: 40px !important;
	margin-right: 5px;
}

.error {
	color: red;
	margin-bottom: 0 !important;

	&::first-letter {
		text-transform: uppercase;
	}
}

.ml-2 {
	margin-left: 0.5rem !important;
}

.ml-3 {
	margin-left: 1rem !important;
}

.mr-2 {
	margin-right: 0.5rem !important;
}

.mb-3 {
	margin-bottom: 1rem !important;
}

.mb-25 {
	margin-bottom: 25px !important;
}

.mt-2 {
	margin-top: 0.5rem !important;
}

.mt-3 {
	margin-top: 1rem !important;
}

.mt-15 {
	margin-top: 15px !important;
}

.mt-30 {
	margin-top: 30px !important;
}

.d-flex {
	display: -webkit-box !important;
	display: flex !important;
}

.d-flex-column {
	-webkit-box-orient: vertical !important;
	-webkit-box-direction: normal !important;
	        flex-direction: column !important;
}

.align-items-center {
	-webkit-box-align: center !important;
	align-items: center !important;
}

.justify-content-end {
	-webkit-box-pack: end !important;
	justify-content: flex-end !important;
}

.justify-content-space-around {
	justify-content: space-around !important;
}

.justify-content-space-between {
	-webkit-box-pack: justify !important;
	        justify-content: space-between !important;
}

.justify-content-center {
	-webkit-box-pack: center !important;
	justify-content: center !important;
}

body.mini-navbar .user-role-element {
	display: none !important;
}

.mini-navbar-links-wrapper {
	display: none;
	position: absolute;
	top: 0;
	right: -90px;
	width: 160px;
	height: 100%;
	z-index: 1;

	ul {
		width: 100%;

		li {
			width: 100%;
			height: 100%;

			&:last-child {
				margin-bottom: 0;
			}
		}
	}
}

.collapse-active {
	height: auto !important;
}

body.mini-navbar .navbar-default .nav li {
	cursor: pointer;

	&:hover {
		background-color: #293846;

		.mini-navbar-links-wrapper {
			display: block;

			ul {
				display: block;

				a {
					display: block;
					width: 100%;
					height: 100%;

					&:hover {
						background-color: transparent;
					}
				}
			}
		}
	}

	a {
		text-align: center;

		i {
			margin-right: 0 !important;
		}
	}
}

.profile-element {
	cursor: pointer !important;
	color: #d6d6d6 !important;
	font-weight: 400 !important;
	margin: 0 !important;
}

.logout-btn {
	display: block;
	font-size: 14px;
	padding: 20px 10px;
	min-height: 50px;
	font-weight: 600;
	cursor: pointer;
}

.navbar-static-top {
	margin-bottom: 0 !important;
}

.nav-label {
	text-transform: capitalize !important;
}

.breadcrumb-btn {
	margin-top: 28px;
	float: right;

	.back-btn {
		color: #fff !important;
	}

	button {
		font-weight: 700 !important;
	}
}

.modal-close-btn {
	padding: 0;
	position: absolute;
	right: 10px;
	z-index: 1;
	background-color: transparent;
	border: none;
	outline: 0;
	font-size: 20px;

	&:hover,
	&:focus,
	&:active {
		border: none;
		background-color: transparent;
	}
}

.modal-footer-area {
	border: none !important;
}

.text-align-center {
	text-align: center !important;
}

.font-15 {
	font-size: 15px;
}

.font-20 {
	font-size: 20px;
}

.opacity-5 {
	opacity: 0.5;
}

.modal-body {
	padding-top: 35px !important;
}

.user-form-wrapper {
	margin: 0 auto;

	.ibox-content {
		form {
			max-width: 500px;
			margin: 0 auto;
		}
	}

	.copy-credentials {
		font-size: 20px;
		display: -webkit-inline-box;
		display: inline-flex;
	}
}

.min-width-80px {
	min-width: 80px;
}

.min-width-100px {
	min-width: 100px;
}

.width-100px {
	width: 100px;
}

.width-150px {
	width: 150px;
}

.min-width-220px {
	min-width: 220px;
}

.non-panel-form {

	.ibox-content,
	.ibox-title {
		border: none !important;
	}
}

.license-wrapper {
	.lincese-action-wrapper {
		width: 350px;

		button {
			margin: 0 3px;
		}
	}
	.license-pagination-wrapper {
		.page-count {
			display: none;
		}
	}
}

.react-datepicker-wrapper {
	display: block !important;

	input {
		margin-left: 0;
	}
}

.add-license-wrapper {
	.ibox-content {
		border-top: none;
	}
}

.send-email-wrapper {
	.ibox-content {
		border-top: none;
	}

	textarea {
		min-height: 230px;
		resize: vertical;
	}

	.label-cc {
		position: absolute;
		right: 10px;
		top: 35px;
		font-size: 14px;
		cursor: pointer;
		&:hover {
			opacity: 0.7;
			text-decoration: underline;
		}
	}

	.lang-selection {
		width: 150px;
		position: absolute;
		top: -6px;
		right: 0;
		padding-right: 0;
	}
}

.role-detail-modal-wrapper {
	::-webkit-scrollbar {
		width: 4px;
		height: 0;
	}
	
	::-webkit-scrollbar-thumb {
		background: #1ab394;
	}
	.modal-dialog {
		width: 50%;
		margin: 10px auto;
		overflow: scroll;
		margin: 10px auto;
		.modal-content {
			height: 100%;
		}
	}
}

.add-role-modal-wrapper {
	::-webkit-scrollbar {
		width: 4px;
		height: 0;
	}
	
	::-webkit-scrollbar-thumb {
		background: #1ab394;
	}

	select {
		min-height: 500px !important;
	}

	.modal-dialog {
		width: 99%;
		height: 97%;
		overflow: scroll;
		margin: 10px auto;
		.modal-content {
			height: 100%;
		}
	}

	.modal-content {
		margin: 0 auto;
	}
}

.mini-navbar {
	.profile-dropdown {
		left: 48px !important;
		top: 45px !important;
		z-index: 2;
	}
}

.m-t-xs {
	margin-top: 5px;
}

.fadeInRight {
	-webkit-animation-name: fadeInRight;
	animation-name: fadeInRight;
}

.animated {
	-webkit-animation-duration: 1s;
	animation-duration: 1s;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
}

@media (min-width: 768px) {
	.confirm-wrapper {
		.modal-dialog {
			width: 435px;
			margin: 30px auto;
		}
	}
}

.org-timeline-wrapper {
	.table-wrapper {
		.table-wrapper {
			padding-bottom: 0 !important;
		}
	}
}

.email-template-wrapper {
	background: #ffffff;
	padding: 20px 40px;

	fieldset {
		display: -webkit-box;
		display: flex;
	}

	.input-wrapper {
		padding-left: 0;
	}
}

.license-update-disabled {

	.checkbox-input,
	.checkmark,
	.lock-btn {
		cursor: not-allowed !important;
	}
}

.checkbox-wrapper {
	display: -webkit-box;
	display: flex;
	flex-wrap: wrap;

	.checkbox-label {
		display: block;
		position: relative;
		padding-left: 35px;
		cursor: pointer;
		font-size: 14px;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
		min-height: 20px;
		line-height: 20px;
		margin-bottom: 15px;
		margin-right: 15px;

		input {
			position: absolute;
			opacity: 0;
			cursor: pointer;
			height: 0;
			width: 0;

			&:checked~.checkmark {
				background-color: #1ab394 !important;

				&:after {
					display: block;
				}
			}
		}

		.checkmark {
			position: absolute;
			top: 1px;
			left: 0;
			width: 20px;
			height: 20px;
			background-color: #eee;

			&::after {
				content: "";
				position: absolute;
				display: none;
				left: 7px;
				top: 3px;
				width: 5px;
				height: 10px;
				border: solid white;
				border-width: 0 2px 2px 0;
				-webkit-transform: rotate(45deg);
				transform: rotate(45deg);
			}
		}

		&:hover {
			input~.checkmark {
				background-color: #ccc;
			}
		}
	}
}


.manage-modules-wrapper {
	.boolean-text {
		width: 35px;
	}

	.empty-data-wrapper {
		color: red;
	}

	.item-input {
		width: 100px;
	}

	.datepicker-wrapper {
		margin: 20px 0;

		p {
			margin-bottom: 0;
			margin-right: 10px;
		}
	}

	.module-manage-date-picker {
		margin: 0 0 0 10px;

		button {
			margin-left: 10px;
		}
	}

	.add-item {
		margin: 20px 0;

		input {
			margin-right: 10px;
			width: 100px;
		}
	}

	.modules-list {
		.module {
			margin-bottom: 10px;

			.module-name {
				font-size: 16px;
				color: #2f4050;
				width: 120px;
			}

			span {
				font-size: 15px;
				letter-spacing: 0.5px;
			}

			.switch {
				display: inline-block;
				height: 22px;
				margin: 0 20px;

				input {
					display: none;

					&:checked~small {
						background: #1ab394;
						-webkit-transition: .3s;
						transition: .3s;

						&::before {
							-webkit-transform: translate(25px, 0px);
							transform: translate(25px, 0px);
							-webkit-transition: .3s;
							transition: .3s;
							background: #fff;
						}
					}
				}

				small {
					display: inline-block;
					width: 50px;
					height: 100%;
					border: 2px solid #1ab394;
					border-radius: 30px;
					position: relative;
					cursor: pointer;

					&::before {
						content: "";
						position: absolute;
						width: 10px;
						height: 10px;
						background: #1ab394;
						border-radius: 50%;
						top: 4px;
						left: 4px;
						-webkit-transition: .3s;
						transition: .3s;
						box-shadow: -3px 0 3px rgba(0, 0, 0, 0.1);
					}
				}
			}
		}
	}
}

.react-datepicker__current-month {
	font-size: 13px !important;
}

.react-datepicker__day--selected {
	background-color: #3ab394 !important;
}

.react-datepicker__day--today {
	color: #000 !important;
	background-color: #fff;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
	font-size: 12px !important;
}

.add-hardware-wrapper {
	.modal-body {
		overflow-y: auto;

		.collapsible-custom-header {
			padding: 10px;
			display: -webkit-box;
			display: flex;
			-webkit-box-align: center;
			align-items: center;
			position: relative;

			span {
				font-size: 16px;
				font-weight: 600;
				letter-spacing: 0.5px;
				cursor: pointer;
			}

			button {
				position: absolute;
				right: 10px;
				padding: 0;
				border: 0;
				background: none;
				font-size: 22px;
				outline: 0;
			}
		}

		.Collapsible__contentInner {
			padding: 10px 20px 0;

			.form-group {
				&:last-child {
					margin-bottom: 0;
				}
			}
		}
	}
}

.datepicker-inline-wrapper {
	.datepicker-input-wrapper {
		label {
			margin-right: 10px;
		}

		display: -webkit-box;
		display: flex;
	}
	.datepicker-actions {
		display: -webkit-box;
		display: flex;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		        flex-direction: column;
		-webkit-box-pack: center;
		        justify-content: center;
	}
}

button:disabled {
	cursor: not-allowed !important;
}

.license-disabled {
	opacity: 0.5;

	input,
	select,
	label,
	button {
		pointer-events: none;
	}
}

.switch-text {
	width: 25px;
}

.item-error {
	color: red;
	margin: 0 20%;
	text-align: right;
}

.min-height-100vh {
	min-height: 100vh;
}

.license-number-wrapper {
	td {
		::-webkit-input-placeholder {
			text-transform: capitalize;
		}
		:-ms-input-placeholder {
			text-transform: capitalize;
		}
		::-ms-input-placeholder {
			text-transform: capitalize;
		}
		::placeholder {
			text-transform: capitalize;
		}
	}

	.search-hw_id,
	.search-windows_version,
	.search-pb_p_version,
	.search-version .search-hardwarekey,
	.search-valid_from,
	.search-valid_to,
	.search-timestamp_call .search-timestamp_send,
	.search-licensenumber,
	.search-timestamp_insert,
	.search-timestamp_update {
		width: 10%;
	}

	.search-auto_license,
	.search-active,
	.search-is_locked {
		width: 5%;
	}

	.search-hardwarekey {
		width: 11%;
	}

	.status,
	.active,
	.description {
		width: 5%;
	}

	.timestamp-call,
	.timestamp-send {
		width: 9%;
	}

	.valid-from,
	.valid-to {
		width: 12%;
	}

	.hardware-id {
		width: 4%;
	}

	.license-number {
		width: 15%;
	}
}

.no-license-allowed {
	margin-top: 28px;
}

.forget-password-wrapper {
	.loginscreen.middle-box {
		width: 400px;
	}

	.logo-name {
		text-align: center;
	}
}

.language-dropdown {
	width: 100px;
	margin: 0 auto 30px;
}

.relate-license-modal {
	.modal-dialog {
		width: 50%;
		margin: 10px auto;
	}
	.modal-content {
		min-height: 500px;
	}
	.relate-customer-search {
		button {
			margin-left: 10px;
		}
	}
	.license-info-wrapper {
		margin: 20px 0 10px;
	}
	.active-customer {
		background: #e0e0e0;
		color: black;
		&:hover {
			background: #e0e0e0;
			color: black;
		}
	}
}
.new-customer-form {
	.ibox-content {
		border-top: none;
	}
	label {
		margin-top: 10px !important;
	}
	form {
		width: 80%;
		margin: 0 auto;
	}
}
.active-backup-card {
	.info-wrapper {
		.info {
			display: -webkit-box;
			display: flex;
			-webkit-box-orient: vertical;
			-webkit-box-direction: normal;
					flex-direction: column;
			.item {
				display: -webkit-box;
				display: flex;
				-webkit-box-align: center;
						align-items: center;
				min-height: 40px;
				margin-bottom: 5px;
				label {
					margin-bottom: 0px;
				}
				p {
					margin: 0;
					margin-left: 0.5rem !important;
				}
			}
		}
		.file-info-wrapper {
			.info-title {
				font-size: 15px;
				font-weight: 600;
				margin-bottom: 10px;
			}
			.file-list {
				width: 100%;
				height: 150px;
				overflow-y: auto;
				ul {
					padding: 5px 15px 5px 2px;
					li {
						list-style-type: none;
						border: none;
						padding: 10px;
						margin-bottom: 5px;
						font-size: 12px;
						box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
					}
				}
				&::-webkit-scrollbar {
					width: 3px;
				}
				&::-webkit-scrollbar-track {
					-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
				}
				&::-webkit-scrollbar-thumb {
				  background-color: #1ab394;
				  outline: 1px solid #1ab394;
				}
			}
		}
	}
}
.highlight-text-yellow {
	background: #909045;
    padding: 3px;
    color: white;
}
